import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import accessLocal from "../utils/accessLocal";

const data = {
  1: ["正の数と負の数", "式の計算", "方程式", "不等式", "１次関数"],
  2: ["平面図形", "空間図形", "図形の性質と合同", "三角形と四角形"],
  3: [
    "式の計算",
    "平方根",
    "２次方程式",
    "２次関数",
    "データの活用",
    "確率と標本調査",
  ],
  4: ["図形と相似", "線分の比と計量", "円", "三平方の定理"],
};

const Unit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (param) => {
    if (location.pathname.startsWith("/vproblem")) {
      accessLocal.saveData("vunit", param);
      navigate("list");
    } else {
      accessLocal.saveData("unit", param);
      navigate("study");
    }
  };

  const problem = location.pathname.startsWith("/vproblem")
    ? accessLocal.loadData("vproblem")
    : accessLocal.loadData("problem");

  return (
    <div>
      <div className="grid justify-center items-end h-[16vh]">
        {location.pathname.startsWith("/vproblem") ? (
          <div className="text-3xl title w-[360px] h-[90px] pt-8 pl-9 text-center ml-4">
            <span>単元学習システム</span>
          </div>
        ) : (
          <div className="text-3xl title_2 w-[320px] h-[90px] pt-8 pl-7 text-center ml-4">
            <span>解説システム</span>
          </div>
        )}
      </div>
      <div className="grid justify-center items-center mt-[20px]">
        <div
          className={`grid grid-cols-2 ${
            data[problem].length > 4 ? "gap-y-4" : "gap-y-6"
          }`}
        >
          {data[problem].map((el, index) => {
            return (
              <div
                key={index}
                className={
                  index === data[problem].length - 1 &&
                  data[problem].length % 2 !== 0
                    ? "col-span-2 flex justify-center"
                    : "col-span-1"
                }
              >
                <button
                  className="lesson-btn w-[400px] h-[170px] shadow-xl rounded-2xl flex flex-col items-center"
                  onClick={() => handleClick(index + 1)}
                >
                  <div className="grid grid-flow-row mt-7">
                    <div className="text-xl font-bold text-blue-900 text-center mb-3">
                      第 {index + 1} 章
                    </div>
                    <div className="text-3xl font-bold w-[300px] text-center">{el}</div>
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Unit;
