import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import accessLocal from "../utils/accessLocal";
import Toast from "toastwind";
import axios from "axios";
import { TokenExpiration } from "../utils/TokenExpiration";

const List = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const token = useSelector((store) => store.authReducer.token);

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    TokenExpiration();
    axios
      .post(
        "/videos/get_video_list",
        {
          problem: accessLocal.loadData("vproblem"),
          unit: accessLocal.loadData("vunit"),
        },
        config
      )
      .then((res) => {
        setData(res.data.result);
        if (res.data.result.length === 0) {
          Toast.show("単元PDFファイルが追加されていません。", {
            status: "error",
          });
        }
      })
      .catch(() => {
        Toast.show("問題が発生しました。後でもう一度お試しください。", {
          status: "error",
        });
      });
    // eslint-disable-next-line
  }, []);

  const handlePdf = async (el) => {
    accessLocal.saveData("unitStudyPdfName", el.vimeoID);
    navigate(`${location.pathname}/pdf_viewer`);
    // setId(el.vimeoID);
  };

  return (
    <div className="overflow-y-auto h-[90vh]">
      <div className="grid justify-center items-end h-[16vh]">
        <div className="text-3xl title w-[360px] h-[90px] pt-8 pl-9 text-center ml-4">
          <span>単元学習システム</span>
        </div>
      </div>
      <div className="grid justify-center h-[84]">
        <div className="text-base text-center text-white mt-10">
          <span>▼スクロール</span>
        </div>
        <div className="grid grid-cols-2 gap-8 mt-10 overscroll-auto pb-[4rem]">
          {data.length === 0
            ? ""
            : data.map((el, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      handlePdf(el);
                    }}
                    className="list-btn w-[340px] h-[90px] shadow-xl rounded-2xl cursor-pointer z-50 flex justify-center items-center"
                  >
                    <div className="grid grid-flow-row">
                      <div className="flex gap-2 items-center text-xl font-bold">
                        <span>{el.url.substring(0, 15).split(".")[0]}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default List;
