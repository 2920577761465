import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import Toast from "toastwind";

import axios from "axios";
import SubjectPDFModal from "./SciencePdf";
const Management = () => {
  const token = useSelector((store) => store.authReducer.token);
  const [subjData, setSubjData] = useState([]);
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    fetchSubj();
  }, []);

  const fetchSubj = async () => {
    const data = {
      subject: location.pathname.split("/")[2],
      chapter: location.pathname.split("/")[3],
    };
    try {
      const response = await axios.post(`/subjects/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "success") {
        setSubjData(response.data.data);
      } else {
        Toast.show(response.data.message, { status: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePdf = async (el) => {
    setId(el.pdf);
  };
  return (
    <div className="flex flex-col items-center">
      <div className="text-3xl title_2 w-[320px] h-[90px] pt-8 pl-7 text-center ml-4">
        <span>トピック</span>
      </div>
      <div className="flex justify-center h-screen items-center">
        <div className="grid grid-cols-2 gap-8 mb-32 ml-10 h-[200px] overscroll-auto">
          {subjData.length === 0
            ? ""
            : subjData.map((el, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      handlePdf(el);
                    }}
                    className="lesson-btn w-[340px] h-[170px] shadow-xl rounded-2xl cursor-pointer z-50 flex justify-center items-center"
                  >
                    <div className="grid grid-flow-row">
                      <div className="flex gap-2 items-center text-xl font-bold">
                        <span>
                          {" "}
                          {el.pdf > 15
                            ? el.pdf.substring(0, 15) + "..."
                            : el.pdf.split('.')[0]}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          {id !== "" && <SubjectPDFModal pdfPath={id} />}
        </div>
      </div>
    </div>
  );
};

export default Management;
