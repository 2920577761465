import React, { useState, useEffect, useRef } from "react";
import { Input, Select, Option } from "@material-tailwind/react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { addQueryParams } from "../redux/actions/queryParamAction";
import axios from "axios";
import Toast from "toastwind";
import "toastwind/dist/style.css";

const ManagePdf = ({
  modalIsOpen,
  crudState,
  selectedVideo,
  closeModal,
  fetchSubj,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.authReducer.token);
  const data = useSelector((store) => store.videoReducer.videos);
  const vid = useSelector((store) => store.videoReducer.latestVid);
  const params = useSelector((store) => store.queryParamReducer, shallowEqual);
  const [queryParams, setQueryParams] = useState(params);
  // const [url, setUrl] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [video, setVideo] = useState({
    subject: "",
    chapter: "",
  });
  const [isUpload, setIsUpload] = useState(false);

  useEffect(() => {
    setIsShow(modalIsOpen);
    if (crudState === "update" && !isUpload) {
      setVideo({
        ...video,
        subject: selectedVideo.subject,
        chapter: selectedVideo.chapter,
      });
      setFile({ name: selectedVideo.name });
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen]);

  useEffect(() => {
    dispatch(addQueryParams(queryParams, token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.sort, queryParams.order]);

  const handleClose = () => {
    setVideo({ subject: "", chapter: "" });
    setFile();
    closeModal();

    setFile(null);
    fileInputRef.current.value = null;
  };

  const handleOK = async () => {
    if (crudState === "create") {
      if (video.subject !== "" && video.chapter !== "") {
        const data = {
          ...video,
          name: file.name,
          pdf: file,
        };

        const response = await axios.post("/subjectPdf", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status === "success") {
          Toast.show(response.data.message, { status: "success" });
          fetchSubj();
          handleClose();
        }
        if (response.data.status === "error") {
          Toast.show(response.data.message, { status: "error" });
          fetchSubj();
          handleClose();
        }
      }
    } else {
      const data = {
        _id: selectedVideo._id,
        name: file.name,
        pdf: file,
      };
      const response = await axios.post("/editSubject", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      Toast.show(response.data.message, { status: "success" });
      if (response.data.message) {
        fetchSubj();
        handleClose();
      }
    }
  };

  useEffect(() => {
    setIsUpload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  const handleSelectChange1 = (value) => {
    setVideo({ ...video, subject: value });
  };

  const handleSelectChange2 = (value) => {
    setVideo({ ...video, chapter: value });
  };

  // -------------vimeo--------------

  const fileInputRef = useRef(null);

  const scienceTitile = ["生物", "化学", "物理", "地学"];

  const openFileSystem = () => {
    fileInputRef.current.click();
  };

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    if (event.target.files.length !== 0) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
    }
  };

  // ---------------------------------------------------------------------------------------------------
  return (
    <>
      <div
        className={`${isShow ? "opacity-100" : "opacity-0 pointer-events-none"} 
        fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300`}
        onClick={handleClose}
      >
        <div
          className="w-[400px] bg-white rounded-xl p-3 transform transition-all duration-300 modal-content shadow-lg"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="h-[35px] flex justify-between">
            <button
              className="bg-gray-200 rounded-full h-6 w-6 absolute top-3 right-3 hover:bg-gray-300"
              onClick={handleClose}
            >
              <i className="fa fa-close"></i>
            </button>
            <div className="grid grid-flow-col items-end px-3">
              <span className="text-2xl font-bold mr-2">他教科問題集PDF</span>
              <span> {crudState === "create" ? "新規登録" : "編集"}</span>
            </div>
          </div>
          {/* Body */}
          <div className="pt-3 px-6">
            <div className="mb-1 flex flex-col">
              {/* <div className="mb-1">管理番号</div> */}
              {/* <Input
                size="lg"
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                name="vid"
                value={
                  crudState === "create"
                    ? vid + 1
                    : selectedVideo
                    ? selectedVideo.vid
                    : ""
                }
                disabled
              /> */}
              <div className="mb-1 mt-3">理科</div>
              <Select
                size="lg"
                name="subject"
                onChange={handleSelectChange1}
                value={video.subject}
                className="custom-select"
                disabled={crudState === "create" ? false : true}
                renderValue={(selected) => scienceTitile[selected]}
              >
                {Array.from({ length: 4 }, (_, index) => (
                  <Option value={`${index + 1}`} key={index + 1}>
                    {scienceTitile[index]}
                  </Option>
                ))}
              </Select>
              <div className="mb-1 mt-3">章</div>
              <Select
                size="lg"
                name="chapter"
                onChange={handleSelectChange2}
                value={video.chapter}
                className="custom-select"
                disabled={crudState === "create" ? false : true}
              >
                {Array.from({ length: 3 }, (_, index) => (
                  <Option value={`${index + 1}`} key={index + 1}>
                    {`第 ${index + 1} 章`}
                  </Option>
                ))}
              </Select>

              <div className="mb-1 mt-3">アップロードするPDFを選択</div>
              {file === null && crudState === "create" ? (
                ""
              ) : (
                <Input
                  size="lg"
                  placeholder=""
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900 cursor-pointer bg-blue-50"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={file?.name}
                  name="url"
                  onClick={openFileSystem}
                  readOnly
                />
              )}
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="application/pdf"
              />
              {file?.name ? (
                ""
              ) : (
                <button
                  className="border-2 border-dashed border-gray-300 rounded h-[45px]"
                  onClick={openFileSystem}
                >
                  <i className="fa fa-plus"></i>
                </button>
              )}
              {isUploading && <div>処理 {Math.floor(progress)}%</div>}
            </div>
          </div>
          {/* footer */}
          <div className="h-[50px] text-end pt-3 mx-6">
            <button
              className="h-[30px] w-[100px] bg-gray-400 text-white text-sm font-bold rounded hover:bg-gray-600 mr-2"
              onClick={handleClose}
            >
              <span>キャンセル</span>
            </button>
            <button
              className="h-[30px] w-[100px] bg-cblue text-white text-sm font-bold rounded hover:bg-blue-500"
              onClick={handleOK}
            >
              <span>保 存</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePdf;
