import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import accessLocal from "../utils/accessLocal";
import { logoutSuccess } from "../redux/actions/authAction";
import { io } from "socket.io-client";
import { setUser } from "../redux/actions/Notification";

const Manage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.notificationReducer.user);

  const handleClick = (route) => {
    if (route === "/") {
      accessLocal.removeItemFromLocal("user");
      dispatch(logoutSuccess());
    }
    navigate(route);
  };
  // const socket = io("http://localhost:4000");

  const socket = io("https://www.chronossystem.com", {
    path: "/api/socket.io",
    transports: ["xhr-polling", "polling"],
    // transports: ["websocket"],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
  });

  socket.on("chat message", (data) => {
    // Dispatch the action to set the user state to true
    dispatch(setUser(data.level));
  });
  return (
    <>
      <div className="grid justify-items-center z-50">
        <div className="lg:w-[950] md:w-[95%] grid lg:justify-items-center sm:justify-items-end mx-5">
          <div className="text-2xl text-white font-bold absolute top-3 left-10 z-20">
            <div className="flex items-center">
              <div className="logo w-10 h-10"></div>
              <span className="ml-2">クロスタ</span>
            </div>
          </div>
          <div className="flex cursor-pointer font-bold">
            <div
              className={`${
                location.pathname === "/muser" ? "active-btn-top" : "btn-top"
              } mr-1 rounded-bl-2xl`}
              onClick={() => handleClick("muser")}
            >
              <i className="fa-solid fa-user"></i>
              <span>ユーザー</span>
            </div>
            <div
              className={`${
                location.pathname === "/mvideo" ? "active-btn-top" : "btn-top"
              } mr-1`}
              onClick={() => handleClick("mvideo")}
            >
              <i class="fa-solid fa-pen"></i> <span>単元学習</span>
            </div>
            <div
              className={`${
                location.pathname === "/mpdf" ? "active-btn-top" : "btn-top"
              } mr-1`}
              onClick={() => handleClick("mpdf")}
            >
              <i className="fa-solid fa-book-open"></i>
              <span>解説</span>
            </div>
            <div
              className={`${
                location.pathname === "/mchat"
                  ? "active-btn-top relative"
                  : "btn-top relative"
              } mr-1`}
              onClick={() => handleClick("mchat")}
            >
              {user==='user' && (
                <div className="bg-red-600 rounded-full absolute right-2 h-3 w-3"></div>
              )}
              <i className="fa-solid fa-comment-dots"></i>
              <span>質問</span>
            </div>
            <div
              className={`${
                location.pathname === "/managesystem"
                  ? "active-btn-top"
                  : "btn-top"
              } mr-1`}
              onClick={() => handleClick("managesystem")}
            >
              <i class="fa-solid fa-book-open-reader"></i> <span>他教科問題集</span>
            </div>

            <div
              className="mr-1 rounded-br-2xl btn-top"
              onClick={() => handleClick("/")}
            >
              <i className="fa-solid fa-right-from-bracket"></i>
              <span>ログアウト</span>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Manage;
