export const checkInputField = (inputs) => {
    if(inputs.problem==="" || inputs.unit===""|| inputs.url===""){
        return true 
    }else{
        return false
    }
}

export const checkUserInputField = (inputs) => {
    if (inputs.level === "user") {
        return Object.values(inputs).some(value => value === '');
    } else {
        const filteredInputs = Object.fromEntries(
            Object.entries(inputs).filter(([key]) => key !== 'school' && key !== 'grade')
        );
        return Object.values(filteredInputs).some(value => value === '');
    }
};