import React from "react";
import PDFLoader from "../components/PDFShowModal";

const Help = () => {
  return (
    <div className="grid justify-center items-center h-[100vh]">
        <PDFLoader id={"help.pdf"} />
    </div>
  );
};

export default Help;
