import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Science = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/managementsystem/science`);
  };
  return (
    <div>
      <div className="grid justify-center items-end h-[16vh]">
        {location.pathname.startsWith("/vproblem") ? (
          <div className="text-3xl title w-[360px] h-[90px] pt-8 pl-9 text-center ml-4">
            <span>単元学習システム</span>
          </div>
        ) : (
          <div className="text-3xl title_2 w-[320px] h-[90px] pt-8 pl-7 text-center ml-4">
            <span>他教科問題集</span>
          </div>
        )}
      </div>
      <div className="grid justify-center items-center pt-20">
        <div className="grid grid-cols-1 gap-y-12">
          <button
            className="lesson-btn w-[400px] h-[190px] shadow-xl rounded-2xl flex justify-center items-center"
            onClick={() => handleClick()}
          >
            <div className="text-3xl font-bold w-[300px]">理科</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Science;
