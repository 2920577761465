import React from "react";

const Loading = () => {
  return (
    <>
      <div>読み込み中</div>
    </>
  );
};

export default Loading;
