// Login action types
export const LOGIN_REQUEST ="LOGIN_REQUEST";
export const LOGIN_SUCCESS ="LOGIN_SUCCESS";
export const LOGIN_FAILURE ="LOGIN_FAILURE";
export const LOGOUT_SUCCESS ="LOGOUT_SUCCESS";
// User crud action types
export const GET_MEMBERS_REQUEST = "GET_MEMBERS_REQUEST";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAILURE = "GET_MEMBERS_FAILURE";

export const ADD_MEMBERS_REQUEST = "ADD_MEMBERS_REQUEST";
export const ADD_MEMBERS_SUCCESS = "ADD_MEMBERS_SUCCESS";
export const ADD_MEMBERS_FAILURE = "ADD_MEMBERS_FAILURE";

export const UPDATE_MEMBERS_REQUEST = "UPDATE_MEMBERS_REQUEST";
export const UPDATE_MEMBERS_SUCCESS = "UPDATE_MEMBERS_SUCCESS";
export const UPDATE_MEMBERS_FAILURE = "UPDATE_MEMBERS_FAILURE";

export const DELETE_MEMBERS_REQUEST = "DELETE_MEMBERS_REQUEST";
export const DELETE_MEMBERS_SUCCESS = "DELETE_MEMBERS_SUCCESS";
export const DELETE_MEMBERS_FAILURE = "DELETE_MEMBERS_FAILURE";
// Video crud action types
export const GET_VIDEOS_REQUEST = "GET_VIDEOS_REQUEST";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_FAILURE = "GET_VIDEOS_FAILURE";

export const ADD_VIDEOS_REQUEST = "ADD_VIDEOS_REQUEST";
export const ADD_VIDEOS_SUCCESS = "ADD_VIDEOS_SUCCESS";
export const ADD_VIDEOS_FAILURE = "ADD_VIDEOS_FAILURE";

export const UPDATE_VIDEOS_REQUEST = "UPDATE_VIDEOS_REQUEST";
export const UPDATE_VIDEOS_SUCCESS = "UPDATE_VIDEOS_SUCCESS";
export const UPDATE_VIDEOS_FAILURE = "UPDATE_VIDEOS_FAILURE";

export const DELETE_VIDEOS_REQUEST = "DELETE_VIDEOS_REQUEST";
export const DELETE_VIDEOS_SUCCESS = "DELETE_VIDEOS_SUCCESS";
export const DELETE_VIDEOS_FAILURE = "DELETE_VIDEOS_FAILURE";
// PDF crud action types
export const GET_PDFS_REQUEST = "GET_PDFS_REQUEST";
export const GET_PDFS_SUCCESS = "GET_PDFS_SUCCESS";
export const GET_PDFS_FAILURE = "GET_PDFS_FAILURE";

export const ADD_PDFS_REQUEST = "ADD_PDFS_REQUEST";
export const ADD_PDFS_SUCCESS = "ADD_PDFS_SUCCESS";
export const ADD_PDFS_FAILURE = "ADD_PDFS_FAILURE";

export const UPDATE_PDFS_REQUEST = "UPDATE_PDFS_REQUEST";
export const UPDATE_PDFS_SUCCESS = "UPDATE_PDFS_SUCCESS";
export const UPDATE_PDFS_FAILURE = "UPDATE_PDFS_FAILURE";

export const DELETE_PDFS_REQUEST = "DELETE_PDFS_REQUEST";
export const DELETE_PDFS_SUCCESS = "DELETE_PDFS_SUCCESS";
export const DELETE_PDFS_FAILURE = "DELETE_PDFS_FAILURE";
// QueryParam action types
export const ADD_QUERYPARAM_REQUEST = "ADD_QUERYPARAM_REQUEST";

