import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import accessLocal from "../utils/accessLocal";

export const subjects = [
  { name: "生物", id: 1 },
  { name: "化学", id: 2 },
  { name: "物理", id: 3 },
  { name: "地学", id: 4 },
];
const ManagementScience = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (id) => {
    accessLocal.saveData("subjectNumber", id);
    navigate(`${location.pathname}/chapter`);
    // navigate(`/managementsystem/${id}`);
  };

  return (
    <div>
      <div className="grid justify-center items-end h-[16vh]">
        {location.pathname.startsWith("/vproblem") ? (
          <div className="text-3xl title w-[360px] h-[90px] pt-8 pl-9 text-center ml-4">
            <span>単元学習システム</span>
          </div>
        ) : (
          <div className="text-3xl title_2 w-[320px] h-[90px] pt-8 pl-7 text-center ml-4">
            <span>他教科問題集</span>
          </div>
        )}
      </div>
      <div className="grid justify-center items-center pt-20">
        <div className="grid grid-cols-2 gap-y-12">
          {subjects.map((subject) => (
            <button
              key={subject.id}
              className="lesson-btn w-[400px] h-[190px] shadow-xl rounded-2xl flex justify-center items-center"
              onClick={() => handleClick(subject.id)}
            >
              <div className="text-3xl font-bold w-[300px]">{subject.name}</div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManagementScience;
