import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { addQueryParams } from "../redux/actions/queryParamAction";
import ManagePdf from "../components/ManagePdf";
import axios from "axios";
const Mvideo = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.authReducer.token);
  const params = useSelector((store) => store.queryParamReducer, shallowEqual);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubj, setSelectedSubj] = useState();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    sort: "created",
    order: -1,
  });
  const videoData = useSelector((store) => store.videoReducer);
  const scienceTitile = ['生物', '化学', '物理', '地学'];

  useEffect(() => {
    dispatch(addQueryParams(queryParams, token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    setQueryParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handlePagePrev = () => {
    if (queryParams.page > 1) {
      const newPage = queryParams.page - 1;
      setQueryParams({ ...queryParams, page: newPage });
    }
  };

  const handlePageNext = () => {
    if (queryParams.page < videoData.totalPages) {
      const newPage = queryParams.page + 1;
      setQueryParams({ ...queryParams, page: newPage });
    }
  };

  const handleLimitChange = (e) => {
    const newLimit = e.target.value;
    setQueryParams({ ...queryParams, limit: newLimit });
  };
  const handleDelete = (id) => {
    // Call the deleteSubject function with the subject ID
    deleteSubject(id);
  };
  const deleteSubject = async (id) => {
    try {
      const response = await axios.post(
        `/deleteSubject`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchSubjects();
    } catch (error) {
      console.error("Error deleting subject:", error);
    }
  };
  // User Modal Open and Close
  const [userVideoIsOpen, setVideoModalIsOpen] = useState(false);
  const [crudState, setCrudState] = useState("");
  useEffect(() => {
    // Fetch subjects when the component mounts
    fetchSubjects();
  }, []);
  const openVideoModal = () => {
    setCrudState("create");
    setVideoModalIsOpen(true);
  };

  const closeVideoModal = () => {
    setVideoModalIsOpen(false);
  };
  const fetchSubjects = async () => {
    try {
      const response = await axios.get("/subjectList", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update the subjects state with the data from the server
      setSubjects(response.data.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };
  return (
    <>
      <div className="grid justify-items-center">
        <div className="lg:w-[950] md:w-[95%]">
          <div className="rounded-none bg-transparent">
            <div className="lg:w-[950] md:w-[95%] grid grid-flow-col bg-transparent">
              <div className="mt-10 mb-5 mx-5 flex items-end">
                <div className="text-3xl text-gray-900 font-bold mr-5">
                  <span>他教科問題集PDF</span>
                </div>
                <button className="btn-add" onClick={openVideoModal}>
                  <span>新規登録</span>
                </button>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="grid grid-flow-col gap-2 items-center pl-4">
                <span>表示 :</span>
                <select
                  className="w-[100px] h-[35px] border-2 border-cblue px-3 rounded-md"
                  onChange={handleLimitChange}
                >
                  <option value="10">10件</option>
                  <option value="20">20件</option>
                  <option value="30">30件</option>
                  <option value="50">50件</option>
                  <option value="100">100件</option>
                </select>
              </div>
              <div className="grid justify-items-end grid-flow-col items-center hidden">
                <span className="p-2">検索 :</span>
                <input
                  type="text"
                  className="h-[35px] w-[200px] border-2 border-cblue px-3 rounded-md active:border-cblue"
                ></input>
              </div>
            </div>
          </div>
          <div className="w-full bg-transparent shadow-none flex-col my-3">
            <table className="mt-4 w-full min-w-max table-auto text-left text-gray-500">
              <thead>
                <tr>
                  <th className="border-y border-blue-gray-100 bg-gray-200 p-4">
                    理科
                  </th>
                  <th className="border-y border-blue-gray-100 bg-gray-200 p-4">
                    章
                  </th>
                  <th className="border-y border-blue-gray-100 bg-gray-200 p-4">
                    PDFファイル名
                  </th>
                  <th className="border-y border-blue-gray-100 bg-gray-200 p-4">
                    編集
                  </th>

                  <th className="border-y border-blue-gray-100 bg-gray-200 p-4">
                    削除
                  </th>
                </tr>
              </thead>
              <tbody>
                {subjects.length > 0 &&
                  subjects.map((item, index) => (
                    <tr
                      key={item}
                      className={
                        index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                      }
                    >
                      <td className="border-y border-blue-gray-100 p-2">
                        {scienceTitile[item.subject-1||0]}
                      </td>
                      <td className="border-y border-blue-gray-100 p-2">
                        {`第 ${item.chapter} 章`}
                      </td>
                      <td className="border-y border-blue-gray-100 p-2">
                        {item.name}
                      </td>

                      <td className="border-y border-blue-gray-100 p-2">
                        <button
                          onClick={() => {
                            setCrudState("update");
                            setVideoModalIsOpen(true);
                            setSelectedSubj(item);
                          }}
                        >
                          <i className="h-4 w-4 fa fa-edit ml-3" />
                        </button>
                      </td>
                      <td className="border-y border-blue-gray-100 p-2">
                        <button onClick={() => handleDelete(item._id)}>
                          <i className="h-4 w-4 fa fa-trash-can ml-3" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <div>
              {queryParams.page} / {videoData.totalPages}
            </div>
            <div className="flex gap-2">
              <button
                className="py-1 px-3 border-[1px] border-gray-500 hover:bg-gray-200 rounded-md font-bold"
                name="prev"
                onClick={handlePagePrev}
              >
                <i className="fa fa-angle-left"></i>
              </button>
              <button
                className="py-1 px-3 border-[1px] border-gray-500 hover:bg-gray-200 rounded-md font-bold"
                name="next"
                onClick={handlePageNext}
              >
                <i className="fa fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ManagePdf
        modalIsOpen={userVideoIsOpen}
        crudState={crudState}
        selectedVideo={selectedSubj}
        closeModal={closeVideoModal}
        fetchSubj={fetchSubjects}
      />
    </>
  );
};

export default Mvideo;
