import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <div>
      <div className="grid justify-center items-center h-[100vh]">
        <div className="flex flex-col items-center">
          <div className="flex mt-20 justify-between items-center">
            <div className="grid grid-flow-row xl:w-[400px] sm:w-[330px] ">
              <button
                className="home-btn h-[380px]"
                onClick={() => handleClick("/vproblem")}
              >
                <div className="grid grid-flow-row -mt-10 mr-1 text-lg">
                  <i className="fa-solid fa-pen text-[32px]"></i>
                  <span className="mt-1 text-[24px]">単元学習</span>
                </div>
              </button>
            </div>
            <div className="grid grid-flow-row xl:w-[400px] sm:w-[330px]">
              <button
                className="home-btn h-[380px]"
                onClick={() => handleClick("/pproblem")}
              >
                <div className="grid grid-flow-row -mt-10 mr-1 text-lg">
                  <i className="fa-solid fa-book-open text-[32px]"></i>
                  <span className="mt-1 text-[24px]">解説</span>
                </div>
              </button>
            </div>

            <div className="grid grid-flow-row xl:w-[400px] sm:w-[330px]">
              <button
                className="home-btn h-[380px]"
                onClick={() => handleClick("/managementsystem")}
              >
                <div className="grid grid-flow-row -mt-10 mr-1 text-lg">
                  <i class="fa-solid fa-book-open-reader text-[32px]"></i>
                  <span className=" text-[24px]">他教科問題集</span>
                </div>
              </button>
            </div>
          </div>
          <div className="flex -mt-20 gap-10 items-center">
            <div className="grid grid-flow-row xl:w-[400px] sm:w-[330px]">
              <button
                className="home-btn1 h-[380px]"
                onClick={() => handleClick("/chat")}
              >
                <div className="grid grid-flow-row  mr-1 text-lg">
                  <i className="fa-solid fa-comment-dots text-[32px]"></i>
                  <span className="mt-1 text-[24px]">質問</span>
                </div>
              </button>
            </div>
            <div className="grid grid-flow-row xl:w-[400px] sm:w-[330px]">
              <button
                className="home-btn1 h-[380px]"
                onClick={() => handleClick("/help")}
              >
                <div className="grid grid-flow-row mr-1 text-lg">
                  <i class="fa-brands fa-searchengin text-[32px]"></i>                  
                  <span className="mt-1 text-[24px]"> 使い方</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
