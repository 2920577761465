import React from "react";

const Error = () => {
  return (
    <>
      <div>エラー</div>
    </>
  );
};

export default Error;
