import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import accessLocal from "../utils/accessLocal";
import { logoutSuccess } from "../redux/actions/authAction";
import { io } from "socket.io-client";
import { setUser } from "../redux/actions/Notification";

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const user = useSelector((store) => store.notificationReducer.user);

  useEffect(() => {
    if (
      location.pathname === "/pproblem/unit/study/pdf_viewer" ||
      location.pathname === "/vproblem/unit/list/video_player"
    ) {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  }, [location.pathname]);

  const handleClick = (route) => {
    if (route === "/") {
      accessLocal.removeItemFromLocal("user");
      dispatch(logoutSuccess());
    }
    navigate(route);
  };

  const handleGoBack = () => {
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPath = location.pathname.substring(0, lastSlashIndex);
    if (!newPath.includes("/")) {
      navigate("/home");
      return;
    }
    navigate(newPath);
  };
  // const socket = io("http://localhost:4000");

  const socket = io("https://www.chronossystem.com", {
    path: "/api/socket.io",
    transports: ["xhr-polling", "polling"],
    // transports: ["websocket"],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
  });
  socket.on("chat message", (data) => {
    // Dispatch the action to set the user state to true
    dispatch(setUser(data.level));
  });
  return (
    <div className="h-auto">
      <div className="text-2xl text-white font-bold absolute top-3 left-10">
        {isShow ? (
          <div className="flex items-center">
            <div className="logo w-10 h-10"></div>
            <span className="ml-2">クロスタ</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="grid grid-flow-row cursor-pointer font-bold absolute bottom-5 right-0 z-[999]">
        <div className="mb-5 rounded-l-lg btn-bottom" onClick={handleGoBack}>
          <i className="fa-solid fa-left-long"></i>
          <span>戻る</span>
        </div>
        <div
          className={`${
            location.pathname.startsWith("/vproblem")
              ? "active-btn-bottom"
              : "btn-bottom"
          } mb-[1px] rounded-tl-lg`}
          onClick={() => handleClick("/vproblem")}
        >
          <i class="fa-solid fa-pen"></i> <span>単元学習</span>
        </div>
        <div
          className={`${
            location.pathname.startsWith("/pproblem")
              ? "active-btn-bottom"
              : "btn-bottom"
          } mb-[1px]`}
          onClick={() => handleClick("/pproblem")}
        >
          <i className="fa-solid fa-book-open"></i>
          <span>解説</span>
        </div>
        <div
          className={`${
            location.pathname === "/managementsystem"
              ? "active-btn-bottom"
              : "btn-bottom"
          } mb-[1px]`}
          onClick={() => handleClick("managementsystem")}
        >
          <i class="fa-solid fa-book-open-reader"></i> <span>他教科問題集</span>
        </div>
        <div
          className={`${
            location.pathname.startsWith("/chat")
              ? "active-btn-bottom"
              : "btn-bottom"
          } mb-[1px]`}
          onClick={() => {
            dispatch(setUser(""));
            handleClick("/chat");
          }}
        >
          {user === "manager" && (
            <div className="bg-red-600 rounded-full absolute right-2 h-3 w-3"></div>
          )}
          <i className="fa-solid fa-comment-dots"></i>
          <span>質問</span>
        </div>

        <div
          className="rounded-bl-lg btn-bottom"
          onClick={() => handleClick("/")}
        >
          <i className="fa-solid fa-right-from-bracket"></i>
          <span className="text-[10px]">ログアウト</span>
        </div>
      </div>
      <Outlet />
      {/* <NotificationContainer /> */}
    </div>
  );
};

export default Layout;
