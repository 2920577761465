import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { pdfjs } from "react-pdf";
import Toast from "toastwind";
import PDFViewer from "./PDFViewer";
import { Spinner } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const PDFLoader = ({ id }) => {
  const [file, setFile] = useState("");
  const [ratio, setRatio] = useState(null);
  const [progress, setLoadingProgress] = useState(0);
  const token = useSelector((store) => store.authReducer.token);
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const res = await axios.get("/helps/getPdf", {
            params: {
              vimeoID: id,
            },
            ...config,
            responseType: "blob",
          });
          if (res.data) {
            const fileURL = URL.createObjectURL(res.data);
            const pdf = await pdfjs.getDocument(fileURL).promise;
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({ scale: 1 });
            setRatio(viewport.width / viewport.height);
            setFile(fileURL);
          }
        }
      } catch (error) {
        console.error(error);
        Toast.show("問題が発生しました。後でもう一度お試しください。", {
          status: "error",
        });
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="pdf-loader rounded shadow-lg  z-50 ]">
      <div className="bg-black w-full flex items-center justify-center">
        {file === "" || ratio === null ? (
          <div className="text-white grid grid-flow-row justify-items-center page-center">
            <Spinner className="h-12 w-12" />
            <div>{progress}%</div>
          </div>
        ) : (
          <PDFViewer pdfFile={file} ratio={ratio} />
        )}
      </div>
    </div>
  );
};

export default PDFLoader;
