import React from "react";
import backgroundVideo from "../assets/imgs/back.mp4";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";

const list = [
  "/home",
  "/vproblem",
  "/vproblem/unit",
  "/pproblem",
  "/pproblem/unit",
  "/pproblem/unit/study",
  "/vproblem/unit/list",
  "/managementsystem",
];

const VideoBackground = () => {
  const location = useLocation();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(list.some(route => location.pathname.startsWith(route)));
  }, [location]);

  return (
    <div className={`video-background ${isShow ? "show" : "hidden"}`}>
      <video autoPlay loop muted className="video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoBackground;
