import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { subjects } from "./ManageSystemScience";
import axios from "axios";
import Toast from "toastwind";
import ProblemSetModal from "../components/ProblemSet";
import { Spinner } from "@material-tailwind/react";
import accessLocal from "../utils/accessLocal";

const Id = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const subjectNumber = accessLocal.loadData("subjectNumber");
  const selectedSubj = subjects.find((item) => item.id === parseInt(subjectNumber));
  const [Subjects] = useState([
    {
      id: 1,
      topics: [
        {
          name: "生物の観察と分類",
          id: 1,
        },
        {
          name: "生物の体のつくり",
          id: 2,
        },
        {
          name: "生命の連続性<br />自然と人間",
          id: 3,
        },
      ],
    },
    {
      id: 2,
      topics: [
        {
          name: "身のまわりの物質",
          id: 1,
        },
        {
          name: "化学変化と原子",
          id: 2,
        },
        {
          name: "化学変化とイオン",
          id: 3,
        },
      ],
    },
    {
      id: 3,
      topics: [
        {
          name: "身のまわりの現象",
          id: 1,
        },
        {
          name: "電流とその利用",
          id: 2,
        },
        {
          name: "運動とエネルギー<br />自然・化学技術",
          id: 3,
        },
      ],
    },
    {
      id: 4,
      topics: [
        {
          name: "大地の変化",
          id: 1,
        },
        {
          name: "天気とその変化",
          id: 2,
        },
        {
          name: "地球と宇宙",
          id: 3,
        },
      ],
    },
  ]);
  const [selectedPDF] = useState(null); // State to store the selected PDF
  const token = useSelector((store) => store.authReducer.token);
  const selectedSubject = Subjects.find(
    (item) => item.id == parseInt(subjectNumber)
  );

  const handleClick = (subj) => {
    setLoading(true);
    // navigate(`/managementsystem/${params.id}/${id}`);
    fetchSubj(subj);
  };

  const fetchSubj = async (chapter) => {
    const data = {
      subject: subjectNumber,
      chapter: chapter,
    };

    try {
      const response = await axios.post(`/subjects/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "success") {
        // setId(response.data.data[0].pdf);
        accessLocal.saveData("unitStudyPdfName", response.data.data[0].pdf);
        navigate(`${location.pathname}/pdf_viewer`);
        setLoading(false);
      } else {
        setLoading(false);
        Toast.show(response.data.message, { status: "error" });
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  return (
    <div>
      <div className="grid justify-center items-end h-[16vh]">
        {location.pathname.startsWith("/vproblem") ? (
          <div className="text-3xl title w-[360px] h-[90px] pt-8 pl-9 text-center ml-4">
            <span>単元学習システム</span>
          </div>
        ) : (
          <div className="text-3xl title_2 w-[320px] h-[90px] pt-8 pl-7 text-center ml-4">
            <span>{selectedSubj.name}</span>
          </div>
        )}
      </div>
      <div className="grid justify-center items-center mt-[100px]">
        <div className="grid grid-cols-3 gap-y-12">
          {selectedSubject?.topics?.map(
            (
              item,
              index // Iterate over the PDFs and render buttons
            ) => (
              <button
                key={item.id}
                className="lesson-btn w-[330px] h-[190px] shadow-xl rounded-2xl flex flex-col items-center"
                onClick={() => handleClick(item.id)}
              >
                <div className="grid grid-flow-row mt-10">
                  <div className="text-xl font-bold text-blue-900 text-center mb-3">
                    第 {index + 1} 章
                  </div>
                  <div className="text-3xl font-bold w-[300px] text-center">
                    <div dangerouslySetInnerHTML={{ __html: item.name }} />
                  </div>
                </div>
              </button>
            )
          )}
        </div>
      </div>
      {loading && (
        <div className="text-white grid grid-flow-row justify-items-center page-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}
      {/* {id !== "" && <ProblemSetModal pdfPath={id} />} */}
    </div>
  );
};

export default Id;
