import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/Login.jsx";
import Home from "../pages/Home.jsx";
import Help from "../pages/Help.jsx";
import Problem from "../pages/Problem.jsx";
import Vplayer from "../pages/Vplayer.jsx";
import Unit from "../pages/Unit.jsx";
import List from "../pages/List.jsx";
import PdfView from "../pages/PdfView.jsx";
import ProblemSetView from "../pages/ProblemSetView.jsx";
import Study from "../pages/Study.jsx";
import Pviewer from "../pages/Pviewer.jsx";
import Chat from "../pages/Chat.jsx";
import Layout from "../pages/Layout.jsx";
import Manage from "../pages/Manage.jsx";
import Muser from "../pages/Muser.jsx";
import Mvideo from "../pages/Mvideo.jsx";
import Mpdf from "../pages/Mpdf.jsx";
import Certification from "../utils/Certification.js";
import AutoLogin from "../utils/AutoLogin.js";
import Forget from "../pages/Forget.jsx";
import Reset from "../pages/Reset.jsx";
import NotFound from "../pages/NotFound.jsx";
import Management from "../components/Management.jsx";
import Id from "../pages/Id.jsx";
import Managesystem from "../pages/Managesystem.jsx";
import ManagementScience from "../pages/ManageSystemScience.jsx";
import Science from "../pages/Science.jsx";
const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AutoLogin>
            <Login />
          </AutoLogin>
        }
      />
      <Route path="/forget" element={<Forget />} />
      <Route path="/reset/:key" element={<Reset />} />
      <Route path="/" element={<Layout />}>
        <Route
          path="/home"
          element={
            <Certification>
              <Home />
            </Certification>
          }
        />
        <Route
          path="/help"
          element={
            <Certification>
              <Help />
            </Certification>
          }
        />
        <Route
          path="/vproblem"
          element={
            <Certification>
              <Problem />
            </Certification>
          }
        />

        <Route
          path="/vproblem/unit"
          element={
            <Certification>
              <Unit />
            </Certification>
          }
        />
          <Route
          path="/managementsystem"
          element={
            <Certification>
              <Science />
            </Certification>
          }
        />
        <Route
          path="/managementsystem/science"
          element={
            <Certification>
              <ManagementScience />
            </Certification>
          }
        />
        <Route
          path="/managementsystem/science/chapter"
          element={
            <Certification>
              <Id />
            </Certification>
          }
        />
        <Route
          path="/managementsystem/science/chapter/pdf_viewer"
          element={
            <Certification>
              <ProblemSetView />
            </Certification>
          }
        />
        <Route
          path="/managementsystem/:id/:id"
          element={
            <Certification>
              <Management />
            </Certification>
          }
        />
        
        <Route
          path="/vproblem/unit/list"
          element={
            <Certification>
              <List />
            </Certification>
          }
        />
        <Route
          path="/vproblem/unit/list/pdf_viewer"
          element={
            <Certification>
              <PdfView />
            </Certification>
          }
        />
        <Route
          path="/vproblem/unit/list/video_player"
          element={
            <Certification>
              <Vplayer />
            </Certification>
          }
        />
        <Route
          path="/pproblem"
          element={
            <Certification>
              <Problem />
            </Certification>
          }
        />
        <Route
          path="/pproblem/unit"
          element={
            <Certification>
              <Unit />
            </Certification>
          }
        />
        <Route
          path="/pproblem/unit/study"
          element={
            <Certification>
              <Study />
            </Certification>
          }
        />
        <Route
          path="/pproblem/unit/study/pdf_viewer"
          element={
            <Certification>
              <Pviewer />
            </Certification>
          }
        />
        <Route
          path="/chat"
          element={
            <Certification>
              <Chat />
            </Certification>
          }
        />
      </Route>
      <Route
        path="/"
        element={
          <Certification>
            <Manage />
          </Certification>
        }
      >
        <Route path="muser" element={<Muser />} />
        <Route path="managesystem" element={<Managesystem />} />
        <Route path="mvideo" element={<Mvideo />} />
        <Route path="mpdf" element={<Mpdf />} />
        <Route path="mchat" element={<Chat />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
