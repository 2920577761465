import React, { useEffect, useState } from "react";
import PDFLoader from "../components/PDFShowModal";
import accessLocal from "../utils/accessLocal";

const PdfView = () => {
    const [id, setId] = useState("");
    useEffect(() => {
        const id = accessLocal.loadData("unitStudyPdfName");
        setId(id);
    },[])
    return (
        <div className="grid justify-center items-center h-[100vh]">
            <PDFLoader id={id} />
        </div>
    );
};

export default PdfView;
