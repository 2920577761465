import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import axios from "axios";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
import { CookiesProvider } from "react-cookie";

// axios.defaults.baseURL = "http://localhost:4000/";
axios.defaults.baseURL = "https://www.chronossystem.com/api/";
// axios.defaults.baseURL = "https://api.chronossystem.com";

// eslint-disable-next-line react/no-unknown-property
const root = ReactDOM.createRoot(document.getElementById("root"));
// function lockAppOrientation() {
//   const appContainer = document.querySelector(#root");
//   const currentOrientation =
//     window.screen.orientation ||
//     window.screen.mozOrientation ||
//     window.screen.msOrientation;
//   const isPortrait =
//     currentOrientation && currentOrientation.type.includes("portrait");

//   if (isPortrait) {
//     appContainer.style.transform = "rotate(90deg)";
//     appContainer.style.width = "100vh";
//     appContainer.style.height = "100vw";
//     appContainer.style.position = "fixed";
//     appContainer.style.top = "0%";
//     appContainer.style.left = "100%";
//     appContainer.style.transformOrigin = "left top";
//     appContainer.style.transition = "transform ease-in-out";
//   } else {
//     appContainer.style.transform = "none";
//     appContainer.style.width = "100vw";
//     appContainer.style.height = "100vh";
//     appContainer.style.position = "static";
//     appContainer.style.top = "auto";
//     appContainer.style.left = "auto";
//     appContainer.style.transformOrigin = "center";
//     appContainer.style.transition = "none";
//   }
// }
// window.addEventListener("orientationchange", lockAppOrientation);

// lockAppOrientation();

root.render(
  <Provider store={store}>
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <BrowserRouter>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </CookiesProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
